import { Component, OnInit } from '@angular/core';
import * as admin from 'firebase-admin';
//import * as $ from 'jquery';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor() {}
    ngOnInit() {
    }
}
