// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyD1UEu2qX52jxyk_E1RAVt-UwM9SbxaDME",
    serviceAccount: "./pk/canine-cadi-firebase-adminsdk-adpbo-d93b8a90fe.json",
    authDomain: "canine-cadi.firebaseapp.com",
    databaseURL: "https://canine-cadi.firebaseio.com",
    projectId: "canine-cadi",
    storageBucket: "canine-cadi.appspot.com",
    messagingSenderId: "495646727763"
  }
};
