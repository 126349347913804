import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { environment } from '../environments/environment';

// AngularFire
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
// for AngularFireAuth
import { AngularFireAuthModule } from 'angularfire2/auth';

import { ToastrModule } from 'ng6-toastr-notifications';
import { NgbModule, NgbActiveModal, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
// Loading Module
import { ChartsModule as Ng2Charts } from 'ng2-charts';
import { LoadingModule, ANIMATION_TYPES } from 'ngx-loading';

import {NgxToggleModule} from "ngx-toggle";

// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
    /* for development
    return new TranslateHttpLoader(
        http,
        '/start-angular/SB-Admin-BS4-Angular-6/master/dist/assets/i18n/',
        '.json'
    ); */
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        NgbModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebase,''),
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        NgxToggleModule,
        ToastrModule.forRoot(), // for toast notifications
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
        LoadingModule.forRoot({
            animationType: ANIMATION_TYPES.threeBounce,
            backdropBackgroundColour: 'rgba(255,255,255,.1)',
            fullScreenBackdrop: true,
            primaryColour: '#000', 
            secondaryColour: '#000',
            tertiaryColour: '#000'
        })
    ],
    declarations: [AppComponent],
    providers: [AuthGuard, NgbActiveModal, NgbDatepickerConfig],
    bootstrap: [AppComponent],
    entryComponents: [
    // ModalComponent
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {}
